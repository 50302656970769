*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #080710;
  font-family: calibri;
}
/* header */

.background {
  /* margin-top: 50px; */
  margin-bottom: 680px;
  /* width: 430px; */
  /* height: 520px; */
  position: relative;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape:first-child {
  background: linear-gradient(#18ada0, #3b87b9);
  left: -80px;
  top: -80px;
}

.shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
}

/* Add this to your existing CSS */
.fontuser {
  position: relative;
}

.fontuser i {
  position: absolute;
  /* left: 10px;  */
  right: 145px;
  top: 10px;
  color: #FFF;
}

.custom-button {
  margin-left: 71px;
  width: 60%;
  margin-top: 36px;
  background: linear-gradient(178deg, hsl(214.86deg 96.52% 45.1%), rgb(188 230 216)); 
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

form {
  height: 619px;
  width: 485px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 57%;
  left: 55%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

form * {
  font-family: calibri;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

label {
  /* display: block; */
  margin-top: 9px;
  font-size: 20px;
  font-weight: 600;
}

input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.521);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

textarea {
  display: block;
  height: 80px;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.07); */
  background-color: rgba(255, 255, 255, 0.521);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

::placeholder {
  color:rgba(0, 0, 0, 0.7);
  font-size: large;
  font-family: calibri;
  font-weight: 900;
}

button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.social {
  margin-top: 30px;
  display: flex;
}

.social div {
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255, 255, 255, 0.27);
  color: #eaf0fb;
  text-align: center;
}

.social div:hover {
  background-color: rgba(255, 255, 255, 0.47);
}

.social .fb {
  margin-left: 25px;
}

.social i {
  margin-right: 4px;
}

.containerscontact {
  margin-bottom: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left-content {
  /* Adjust the width as needed */
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-content {
  /* Adjust the width as needed */
  flex: 1;
  position: relative;
  box-shadow: 0 0 40px;
  flex-direction: column;
  height: 114%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.error-field {
  border: 1px solid red;
  /* Set the border color to red for highlighting */
}

.success-modal {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.success-modal p {
  font-size: 18px;
  margin-bottom: 20px;
}

.success-modal button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.success-modal button:hover {
  background-color: #45a049;
}

.success-modal i {
  color: green;
  /* Change 'green' to the desired color */
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #ff5555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip.visible .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 600px) {

  .background {
    margin-top: 80px;
    margin-bottom: 110px;
    width: 430px;
    height: 520px;
    position: relative;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  .background .shape {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
  }

  .shape:first-child {
    background: linear-gradient(#1845ad, #23a2f6);
    left: -80px;
    top: -80px;
  }

  .shape:last-child {
    background: linear-gradient(to right, #ff512f, #f09819);
    right: -30px;
    bottom: -80px;
  }

  form {
    height: 520px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  form * {
    font-family: calibri;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
  }

  form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
  }

  label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
  }

  input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
  }

  ::placeholder {
    color: #e5e5e5;
  }
 
  button {
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }
}
.loginheader{
  font-family: 'calibri';
    /* background: linear-gradient(5deg, rgba(2, 41, 51, 0.95), transparent); */
    /* border-radius: 40px; */
    width: 250px;
    display: flex;
    text-align: center;
    justify-content: center;
    /* padding-right: 41px; */
    margin-left: 80px;
    text-shadow: 2px 2px 4px #000;
}