@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,700);

body {
  font-family: calibri;
  font-weight: lighter;
  background: rgb(10, 75, 128);
  /* background:
    -moz-linear-gradient(left,
      rgb(38, 165, 165) 0%,
      rgb(101, 230, 223) 100%); */
  background:
  linear-gradient(181deg, #0170B9 0%, #FFF 100%);
  /* linear-gradient(1deg, #d4a02499,rgb(21 181 92 / 71%)); */
    /* -webkit-linear-gradient(left,
      rgb(8, 132, 214) 0%,
      rgb(64, 196, 196) 100%);  */
      /* rgba(0, 0, 0, 0.12) 100%); */
       /* background-image: url("https://hips.hearstapps.com/hmg-prod/images/scuba-diver-observing-a-large-shipwreck-completely-royalty-free-image-1676048622.jpg"); */
      /* background-size: "cover"; */
      /* position: relative;  */
      /* background-repeat: no-repeat; */
       /* background: url(https://res.cloudinary.com/ritalbradley/image/upload/v1657644013/pexels-elijah-o_donnell-3473569_o7ohfd.jpg) */
    /* center center/cover no-repeat; */
  /* min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  flex-direction: column; */
}

.colors{
  background:
    /* -webkit-linear-gradient(left,
      rgb(8, 132, 214) 0%,
      rgb(64, 196, 196) 100%);  */
      /* linear-gradient(3deg, #d4a02499,rgb(21 181 92 / 71%)); */
      linear-gradient(181deg, #0170B9 0%, #89bddf 100%);
}
.accordion {
  -webkit-box-shadow: 0px 13px 23px -13px rgba(0, 0, 0, 0.5);
  width: 80%;
  background-color: transparent;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 56px;
  flex-direction: 'row';
  display: "flex";
}

/* .GeeksForGeeks {
  background-image: url("https://hips.hearstapps.com/hmg-prod/images/scuba-diver-observing-a-large-shipwreck-completely-royalty-free-image-1676048622.jpg");
  background-size: "cover";
  height: 128vh;
  margin-top: -70px;
  font-size: 18px;
  background-repeat: no-repeat;
} */

.title {
  /* height: 100%; */
  /* width: 400px; */
  /* width: 98.2 %; */
  /* background-color: rgba(0, 0, 0, .4); */
  /* background-color: rgba(255, 255, 255, .3); */
  color: #000000;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */
  text-align: justify;
  line-height: 1.3;
  font-weight: lighter;
  position: relative;
  padding: 6px;
  z-index: 2000;
  border-radius: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  transition: all .2s ease-in;
  font-family: calibri;
  background-color:rgb(255, 255, 255);
}

.title-text {
  margin-left: 10px;
  color: #000000;
  font-size: 21px;
  font-weight: 900;
  font-family:calibri;
  /* text-shadow: 2px 2px 5px #000000; */
}

.title:hover {
  cursor: pointer;
  background-color:rgba(255, 255, 255,0.6);
}

.title:active {
  background-color: rgba(255, 255, 255,0.6);;
}

.content {
  height: 30px;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  color: white;
  font-size: 19px;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: -30px;
  text-align: justify;
  transition: all 200ms cubic-bezier(0.600, -0.280, 0.735, 0.045);
}

.content-open {
  margin-top: 0px;
  height: auto;
  background-color: rgba(0, 0, 0, .1);
  transition: all 350ms cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.content-text {
  padding: 15px;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all .2s ease-in;
}

.content-text-open {
  visibility: visible;
  opacity: 1;
  transition: all .8s ease-in;
}

.fa-angle-down {
  font-size: 34px;
  /* color: rgba(29, 97, 161, 0.5)5) */
  color: #000;
  transition: all .6s cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.fa-rotate-180 {
  font-size: x-large;
  /* color: rgba(255, 255, 255, 1); */
  color: #000;
}

.arrow-wrapper {
  position: absolute;
  margin-left: 375px;
}
.header{
  font-family:calibri;
  font-size: 34px;
}
.header-for-title{
  font-family:calibri;
  font-size: 34px;
}