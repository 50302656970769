@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fadeInOut {
  animation: fadeInOut 1s ; /* Adjust the duration as needed */
  
}

@keyframes flipIn {
  from {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  to {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

.flipIn {
  animation: flipIn 1s ease-in-out, bounce 1s forwards 2s; /* Adjust the delay for bounce */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 1s forwards;
  
}
