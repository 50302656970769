// Colors
$white: #fff;
$black: #000;
$primary: #d4a024;
$gray: #616161;

// Base style
html,
body,
.container { height: 100%; }

body {
  font-family: calibri;
  font-size: 14px;
  overflow-y: overlay;
  overflow-x: hidden;
}

.container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
//   background-color: #fff;
  flex-direction: row;
}

.title {
  // font-size: 38px;
  color: $gray;
  // font-style: italic;
  font-weight: 800;
}
.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or 'contain' based on your preference */
}
// Timeline
.timeline {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      // box-shadow: -230px 0 150px 60vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
      }
      
      &-content {
         right: 12%; 
         font-size: 16px;
         text-align: left;
        }
    }
  }
}


.timeline2 {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      // box-shadow: -230px 0 150px 60vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
      }
      
      &-content {
         right: 12%; 
         font-size: 16px;
         text-align: left;
        }
    }
  }
}

.timeline3 {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      // box-shadow: -230px 0 150px 60vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
      }
      
      &-content {
         right: 12%; 
         font-size: 16px;
         text-align: left;
        }
    }
  }
}

.timeline4 {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      // box-shadow: -230px 0 150px 60vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
      }
      
      &-content {
         right: 12%; 
         font-size: 16px;
         text-align: left;
        }
    }
  }
}

.timeline5 {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      // box-shadow: -230px 0 150px 60vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
      }
      
      &-content {
         right: 12%; 
         font-size: 16px;
         text-align: left;
        }
    }
  }
}

.timeline6 {
  width: 100%;
  background-color: #fff;
  // box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    display: flex;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      box-shadow: -230px 0 150px 21vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      // max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        // bottom: -12%;
        width: 240px;
        // height: 50%;
        height: 79%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
        box-shadow: -230px 0 166px 19vw rgba(0, 0, 0, 0.7);
      }
      
      &-content {
         right: 1%; 
         font-size: 20px;
         text-align: left;
        }
    }
  }
}

.timeline7 {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);

  .swiper-container {
    height: 600px;
    width: 100%;
    position: relative;
  }
  
  .swiper-wrapper { transition: 2s cubic-bezier(.68,-0.4,.27,1.34) .2s; }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    object-fit: contain;
  
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: -115%;
      bottom: -10%;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .7);
      // box-shadow: -230px 0 150px 60vw rgba($black, .7);
      border-radius: 100%;
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
    }

    .timeline-year {
      display: block;
      // font-style: italic;
      font-size: 42px;
      margin-bottom: 50px;
      transform: translate3d(20px, 0, 0);
      color: $primary;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 800;
    //   font-size: 34px;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
    }

    .timeline-text {
      line-height: 1.3;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
    }
    
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }
      
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }
      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15%!important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    // font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;
  
    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba($white, .2);
    }
  
    &-bullet {
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $primary;
      margin: 15px 0!important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -32.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $primary;
        transform: scale(0);
        transition: .2s;
      }
      
      &-active {
        color: $primary;

        &::before { transform: scale(1); }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    top: 15%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(-3px); }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover { transform: translateX(3px); }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      &::after {
        right: -30%;
        bottom: -8%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 50vw rgba($black, .7);
      }

      &-content {
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
      
      .timeline-year {
        margin-bottom: 0;
        font-size: 32px;   
      }
      
      .timeline-title {
        font-size: 31.6px;
        margin: 0;
        color: lightseagreen;
      }
    }
    
    .swiper-pagination { display: flex; }
    
    .swiper-button-prev {
      top: 15%;
      left: auto;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(-3px, 10px); }
    }
    
    .swiper-button-next {
      top: auto;
      bottom: 15%;
      right: 15%;
      transform: rotate(90deg) translate(0, 10px);
      
      &:hover { transform: rotate(90deg) translate(3px, 10px); }
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-slide {
      &::after {
        right: -20%;
        bottom: -12%;
        width: 240px;
        height: 50%;
        // box-shadow: -230px 0 150px 39vw rgba($black, .7);
      }
      
      &-content {
         right: 12%; 
         font-size: 16px;
         text-align: left;
        }
    }
  }
}