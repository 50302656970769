@import url(https://fonts.bunny.net/css?family=amita:700);

/* body {
  background: url(https://res.cloudinary.com/ritalbradley/image/upload/v1657644013/pexels-elijah-o_donnell-3473569_o7ohfd.jpg)
    center center/cover no-repeat;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
} */

.header {
  color:#ffffff;
  /* color: #fefae0; */
  font-family: calibri;
  margin: 20px auto;
  width: 600px;
  font-size: 3rem;
  text-align: center;
  border-bottom: 10px double #bc6c25;
  text-shadow: rgb(0, 0, 0) 2px 2px 7px;
}
.header2 {
  color: #ffffff;
  font-family: calibri;
  margin: 20px auto;
  width: 990px;
  font-size: 3rem;
  text-align: center;
  border-bottom: 10px double #bc6c25;
  text-shadow: rgb(0, 0, 0) 2px 2px 7px;
}
.imges {
  width: auto;
  height: 300px;
  margin: 25px;
  border: 10px solid #000;
  /* -webkit-filter: sepia(100%); */
  /* filter: sepia(100%); */
  transition-duration: 1s;
}

.imges:hover {
  border: 5px solid #fff;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: none;
  filter: none;
}

footer {
  background-color: #bc6c25;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 100vw;
}
.presscontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
