* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: calibri;
}

body {
  font-family: calibri;
  background-color: black;
  color: white;
}
.custom-progress-bar {
  height: 4px; /* Adjust the height of the progress bar */
  background: #2196F3; /* Change the color of the progress bar */
  width: 0;
  transition: width 0.5s ease; /* Adjust the transition duration */
}
.slick-slider {
  margin: 4px;
  gap: 20px;
  margin-top: 4px;
  margin-bottom: 30px;
  width: auto;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: auto;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: #4787ed;
}

.slick-next {
  z-index: 4000;
  right: -0px;
  position: absolute;
}

.slick-prev {
  z-index: 4000;
  left: -8px;
  position: absolute;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 45px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 87px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.paragraph {
  background: rgb(21 127 153 / 95%);
  text-align: center;
  width: 48%;
  color: antiquewhite;
}

header nav {
  display: flex;
  align-items: center;
}

header nav li {
  list-style-type: none;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
}

img {
  object-fit: cover;
}

.bg-container {
  position: relative;
}

.absolute-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-text h1 {
  font-size: 5rem;
}

.bg-container img {
  width: 100%;
  height: 90vh;
  box-shadow: 0 0 40px;
}

.card-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line */
  width: 100%;
  margin: auto;
}

.card {
  height: 900px;
  box-shadow: 0 0 40px -10px rgba(15, 15, 15, 0.74);
  flex-basis: calc(50% - 20px); /* Adjusted flex-basis to allow for gap */
  margin: 10px;
  line-height: 1.4;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.card:hover {
  box-shadow: 0 0 40px -10px rgba(241, 240, 240, 0.25);
  background: #fafafa;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.86);
  transform: scale(0.86);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.card img {
  width: 100%;
  height: auto;
  max-height: 450px;
  object-fit: cover;
}

.subscribe-section {
  width: 80%;
  display: block;
  margin: auto;
  text-align: center;
  background-color: tan;
  padding: 20px;
}

.subscribe-section button {
  padding: 10px;
  outline: none;
  cursor: pointer;
}
