@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,200,700,500);

html,
body {
    height: 100%;
    margin: 0;
    font-family: calibri;
    padding: 0;
    overflow: auto;
    font-weight: lighter;
    background:
        -moz-linear-gradient(left,
            rgb(92, 171, 202) 0%,
            rgb(112, 222, 249) 100%);
    background:
        -webkit-linear-gradient(left,
            rgb(67, 129, 158) 0%,
            rgb(112, 233, 249) 100%);
}

.container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* background: #FCE4EC; */
    /* margin: 47px; */
    height: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-flow: row;
    -ms-flex-flow: row;
    flex-flow: row;
    /* overflow: hidden; */
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: background .5s ease;
    transition: background .5s ease;
    /* gap: 80px; */
    flex-wrap: wrap;
    /* justify-content: space-around; */
    align-items: flex-start;
}

.card-wrapper {
    position: relative;
    width: 435px;
    height: 580px;
    /* flex: 1 1 23.33%; */
    margin: 10px;
    overflow: visible;
}

.user-image {
    /* background: url('https://dl.dropboxusercontent.com/s/luspdnjjohln22e/pexels-photo-59576.jpeg'); */
    background-size: cover;
    height: 70%;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.username {
    font-family: calibri;
    text-transform: capitalize;
    position: absolute;
    bottom: 0px;
    width: 100%;
    color: #fff;
    font-size: 27px;
    font-size:23px;
    font-weight: 200;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 35px;
    /* padding-left: 80px; */
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.4);
    height: 48px;
    z-index: 1;
}

.add-button {
    height: 40px;
    width: 40px;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    position: absolute;
    left: 20px;
    bottom: -20px;
    color: #fff;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 500;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    cursor: pointer;
}



.add-button:hover {
    background: #000;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.user-info {
    height: 17%;
}

.quote {
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    /* font-weight: bold; */
    padding-top: 16px;
    text-align: center;
    font-family: calibri;
}

.main-window {
    background: #fff;
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    width: 90%;
    position: relative;
    z-index: 20;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    border-radius: 20px;
}

@media (max-width: 600px) {
    .card-wrapper {
        width: 100%;
        height: 100%;
    }
    .container {
        display: flex;
        flex-direction: row;
        flex-direction: row;
        align-items: center;
    }
    .window {
       
        width: 100%;
        /* Make windows take full width */
        max-width: 100%;
        /* Limit maximum width to 100% */
    }
}