.Footer {
  z-index: 60000;
  position: sticky;
  padding: 12px;
  background:linear-gradient(184deg, #0a75bc, rgb(10 117 188));
  /* background: linear-gradient(184deg, #a1baca, rgb(161 186 202)); */
  color: #fafafa;
  font-size: 18px;
  overflow-x: hidden;
  text-align: center;
  margin: 2px
}

.Footer a {
  padding-bottom: 1px;
  border: 0;
  color:  hsl(0, 0%, 90%);
}
.Footer a:focus,
.Footer a:active,
.Footer a:hover {
  color: #fff;
  text-decoration: underline;
}

.Footer-credits {
  margin: 0;
  padding: 0;
}

.Footer-credit {
  display: block;
  margin: 0;
}

.Footer-creditSeparator {
  display: none;
}

/* The GitHub button updates the link to be a span */
.Footer-social > span {
  margin-right: 1em;
}

/* If loading the social button fails */
.Footer-social a,
.Footer-social iframe {
  display: inline-block;
  margin: 0 0 1em;
  vertical-align: top;
}
.FooterIcon{
  width: 50px;
  height: 50px;
}
@media (--break-md) {
  .Footer-credit {
    display: inline-block;
    margin: 0 0.25em;
  }
  .Footer-creditSeparator {
    display: inline-block;
    padding: 0 0.25em;
  }
}